import './InstagramFeed.scss';
import React from 'react';
import PropTypes from 'prop-types';
import mobile from 'is-mobile';

export default class InstagramFeed extends React.Component {
  render () {

    const { data, /*meta, pagination*/ } = this.props;

    if (!data) {
      return null;
    }

    return (
      <React.Fragment>
        <h2>
          <a href="https://www.instagram.com/skyline_prague" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </h2>
        <div className="InstagramFeed">
          {data.map( ({id, type, images, videos, caption}) =>
            <figure key={`ig-post--${id}`}>
          
              {type === 'video'
                ? <video
                    controls
                    width={images.low_resolution.width}
                    poster={images[mobile ? 'low_resolution' : 'standard_resolution'].url}
                    height={images.low_resolution.height}
                    src={videos[mobile ? 'low_bandwidth' : 'standard_resolution'].url} 
                  />
                : <img 
                    alt=""
                    className="InstagramFeed__image"
                    src={images[mobile ? 'low_resolution' : 'standard_resolution'].url}
                    height={images.low_resolution.height}
                    width={images.low_resolution.width} 
                    title={caption ? caption.text : null}
                  />
              }
            </figure>
          )}
        </div>
      </React.Fragment>
    )
  }
}

InstagramFeed.propTypes = {
  feed: PropTypes.object
}