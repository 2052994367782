import React from 'react';
import SocialMedia from 'components/SocialMedia/SocialMedia';

export default class Footer extends React.PureComponent {
  render() {
    return (
      <footer className="grid" data-layout="space-between">
        <div className="grid--cell">
          <SocialMedia key="social-media"/>
        </div>

        <div className="grid--cell">
          <a href="https://www.dropbox.com/sh/h83hk9zgahjbzkp/AABMKkhB1dtDwJTMtk7MNM6ga?dl=0" className="dropbox" target="_blank" rel="noopener noreferrer">
            Info
          </a>
        </div>

        <div className="grid--cell contacts">
          <div>
            booking <a href="mailto:david@dsmacku.com">david@dsmacku.com</a>
          </div>
          <div>
            press <a href="mailto:katerina.novakova@championship.cz">katerina.novakova@championship.cz</a>
          </div>
        </div>
      </footer>
    )
  }
}