import './SocialMedia.scss';

import React from 'react'
const MEDIA = [
  {
    'id': 'facebook',
    'url': 'http://www.facebook.com/skyline.prague'
  },
  {
    'id': 'youtube',
    'url': 'https://www.youtube.com/user/skylinetv'
  },
  {
    'id': 'instagram',
    'url': 'https://www.instagram.com/skyline_prague'
  },
]


const SocialMedia = () => {
  return(
    <ul className="social-media">
      {MEDIA.map( soc => <Soc key={soc.id} {...soc}/>)}
    </ul>
  )
}

const Soc = ({id, url}) => {
  return(
    <li className={`social-media__${id}`}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <span>{id.toUpperCase()}</span>
      </a>
    </li>
  )
}


export default SocialMedia