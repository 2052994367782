import './App.scss'
import React, { Component } from 'react'
import 'datejs'
import { Switch, Route } from 'react-router-dom'
import mobile from 'is-mobile';
import {
  getEvents,
  getInstagramFeed
} from 'helpers/getData'
import Loader from 'components/Loader/Loader';
import Homepage from 'routes/Homepage/Homepage';
import Events, { filterOutPastEvents } from 'routes/Events/Events';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
// import Navigation from 'components/Navigation/Navigation';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventsList: null,
      igFeed: null,
      isLoading: false,
      section: this.props.location.pathname.split('/')[1] || null
    }
  }

  getData = () => {
    this.setState({
      isLoading: true
    })

    Promise.all([
      getEvents(), 
      // getInstagramFeed()
    ])
      .then( ([events, igFeed]) => {
        this.setState({
          eventsList : filterOutPastEvents(events),
          igFeed,
          isLoading: false
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoading: false
        })
      })
  }

  componentDidMount () {
    this.getData();
    document.body.dataset.mobile = mobile();
  }

  render() {
    const {
      eventsList,
      igFeed
    } = this.state;

    return (
      <div
        className="App"
        data-section={this.state.section}
      >
        {this.state.isLoading && <Loader />}
        
        {/* <Navigation /> */}
        
        <Header section={this.state.section} />

        <main className="page-content">
          <Switch>
            <Route path="/" exact render={() => 
              <Homepage events={eventsList} igFeed={igFeed} />
            } />
            <Route path="/koncerty/:id?" component={Events} />
            <Route path="*" component={() => <h2>Stránka nenalezena</h2>} />
          </Switch>
        </main>
        
        <Footer />

      </div>
    )
  }
}

export default App