import React from 'react'
import animateScrollTo from 'animated-scroll-to';
import mobile from 'is-mobile';
import './Header.scss'
// import SocialMedia from 'components/SocialMedia/SocialMedia';

class Header extends React.PureComponent {

  componentDidMount() {
    if (!this.props.section && this._TitleElm) {

      window.onscroll = () => {
        if (window.pageYOffset >= 100) {
          this._TitleElm.dataset.scrolled = true;
        } else {
          delete this._TitleElm.dataset.scrolled;
        }
      }
    }
  }

  render() {

    return (
      <header>
      <h1
        className="site-title" 
        onClick={scrollOnClick}
        ref={ (TitleElm) => this._TitleElm = TitleElm }
      >
        Skyline
      </h1>
      {/* <SocialMedia /> */}
    </header>
    )
  }
}
const scrollOnClick = () => {
  const offset_ = mobile() ? document.querySelector('header').offsetHeight : window.innerHeight;
  animateScrollTo(offset_, {
    element: window,
    speed: 300,
    minDuration: 500,
    maxDuration: 800
  });
}

export default Header