import React from 'react'
import ReactDOM from 'react-dom'
import './Loader.scss';

const LoaderHTML = () => (
	<div className="Loader" />
)

const Loader = (props) => {
	const appContent = document.getElementById('root');

	if (!appContent) return null;
	
	return ReactDOM.createPortal(<LoaderHTML />, appContent);
}

export default Loader