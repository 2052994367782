import './Homepage.scss'
import React from 'react';
import PropTypes from 'prop-types';
import Events from 'routes/Events/Events'
import InstagramFeed from 'components/InstagramFeed/InstagramFeed'
import Youtube from 'components/Youtube/Youtube';

export default class Homepage extends React.Component {
	render () {

		return (
			<div className="Homepage">
				<div className="column">
					<div className="Homepage__Youtube ">
						<Youtube />
					</div>

					<div className="Homepage__Events">
						<h2>Live dates</h2>
						<Events events={this.props.events} />
					</div>
				</div>
				{
					this.props.igFeed &&
					this.props.igFeed.meta.code !== 400 && 
					this.props.igFeed.data &&
						<div className="Homepage__InstagramFeed column">
							<InstagramFeed {...this.props.igFeed} />
						</div>
				}
			</div>
		)
	}
}

Homepage.propTypes = {
		events: PropTypes.array
	, igFeed: PropTypes.object
}