import './Youtube.scss'
import React, { PureComponent } from 'react';
import size from 'lodash/size';
import { getYoutubeVideo } from 'helpers/getData'

class Youtube extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      videoUrl: null
    }
  }

  componentDidMount() {
    getYoutubeVideo().then( (items) => {
        const videoUrl = size(items[0].fields)
          ? items[0].fields.url.replace('watch?v=', 'embed/')
          : 'https://www.youtube.com/embed?max-results=1&amp;rel=0&amp;listType=user_uploads&list=skylinetv';

        return this.setState({ videoUrl })
      }
    )
  }

  render () {

    if (!this.state.videoUrl) {
      return null
    }
    return (
      <React.Fragment>
        <h2>
          <a href="https://www.youtube.com/user/skylinetv" target="_blank" rel="noopener noreferrer">
            Youtube
          </a>
        </h2>
        <div className="Youtube">
          <iframe 
            height="100%" 
            src={this.state.videoUrl} 
            frameBorder="0" 
            allow="autoplay; encrypted-media" 
            allowFullScreen 
            title="Skyline"
          />
        </div>
      </React.Fragment>
    )
  }
}
export default Youtube;