import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, withRouter } from 'react-router-dom'
import MyProvider from './Context'

const AppComponent = withRouter(props => <App {...props}/>);

const render = (Component) => {
  ReactDOM.render((
    <MyProvider>
      <BrowserRouter>
        <Component /> 
      </BrowserRouter>
    </MyProvider>
    ), document.getElementById('root')
  )
};

render(AppComponent)

if (module.hot) {
	module.hot.accept('App', () => {
		const NextApp = require('App').default;
		render(NextApp);
	});
}