import React from 'react';

// Create new context
export const Context = React.createContext()

// Then create a Provider Component
export default class MyProvider extends React.Component {
  state = {
    language: 'en'
  }

  changeLanguage = ({target}) => {
    this.setState({
      language: target.dataset.language
    })
  }

  render() {
    return (
      <Context.Provider value={{
        state: this.state,
        changeLanguage: this.changeLanguage,
      }}>
        {this.props.children}
      </Context.Provider>
    )
  }
}