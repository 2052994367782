// import { markdown } from 'markdown'
import renderHTML from 'react-render-html';

const DAYS_LIST = ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'];

/**
 * Takes time,date and creates Date instance
 * @param {String} timeDate 
 * @return {Object}
 */
export function parseDate (timeDate) {
  const DATE = Date.parse(timeDate)
  if (!DATE) {
    return null
  }

  const _minutes = DATE.getMinutes()
  const _parsedMinutes = String(_minutes).length < 2 ? `0${_minutes}` : _minutes

  return {
      date_string: `${DATE.getDate()}.${DATE.getMonth() + 1}.${DATE.getFullYear()}`
    , time_string: `${DATE.getHours()}:${_parsedMinutes}`
    , date: DATE
    , day: DAYS_LIST[DATE.getDay()]
  }
}

export function isDateInPast (timeDate, limit) {
  const date_ = new Date(timeDate);
  const currentDate_ = new Date();
  currentDate_.setHours(0,0,0,0)

  return date_ < currentDate_;
}

export function formatText (text) {
  let _formatedText = ''
  let _paragraphs = text.split('\n\n')

  _paragraphs.map( (pText, i) => {
    return _formatedText += `<p>${pText}</p>`
  })  

  // console.log(_formatedText)
  console.log(text)

  return renderHTML(_formatedText)
  // return _formatedText
}