import './Events.scss'
import * as React from 'react';
import { parseDate } from 'helpers/helpers'

export const EventInfo = ({
      name,
      description,
      id,
      fbEvent,
      place,
      startTime,
      history,
      tickets
}) => {
  const date_ = parseDate(startTime)
  // onClick={() => history.push(`/koncerty/${id}`)}
  return(
    <div
      className="Events__item"
      
    >
      <div className="Events__item--day">
        <b>{date_ && date_.date_string}</b>
        <span className="Events__item--dayofweek">
          {date_.day}
        </span>
        {fbEvent &&
          <a 
            href={fbEvent} 
            className="Events__item--fb-event" 
            title="Facebook event" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            FB Event
          </a>
        }
        {tickets &&
          <a 
            href={tickets} 
            className="Events__item--tickets" 
            title="Buy tickets" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Buy tickets
          </a>
        }
      </div>
      <div className="Events__item--info">
        {name && 
          <h3 className="Events__item--name">
            {
              name
                .replace('Skyline - ', '')
                .replace('Skyline / ', '')
                .replace('Skyline ', '')
                .replace('Skyline', '')
            }
          </h3>
        }
        {description &&
          <p className="Events__item--description">
            {description}
          </p>        
        }
      </div>
    </div>
  )
}

export default EventInfo