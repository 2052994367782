import './Events.scss'
import React from 'react'
import EventInfo from './Events.Info'
import { isDateInPast } from 'helpers/helpers';

class Events extends React.PureComponent {
  
  render() {
    const { events, history } = this.props;

    if (!events) {
      return null;
    }

    if (events && !events.length) {
      return <p className="Events" style={{ textAlign: 'center' }}>
        Aktuálně nemáme naplánované žádné koncerty.
      </p>
    }

    return (
        <div className="Events">
          {events.map( ({sys, fields}, index) => 
            <EventInfo 
              key={sys.id}
              history={history}
              id={sys.id}
              index={index}
              {...fields}
            /> 
          )}
        </div>
    )
  }
}

export const filterOutPastEvents = (events) => {
  return events.filter( ({fields}) => {
    return !isDateInPast( fields.startTime );
  })
}

export default Events;
