import { createClient } from 'contentful'
const client = createClient({
  space: 'qe7xl5q14j4y',
  accessToken: 'e65ddb0de9a3c827035dfaf48e94259c8288ccaed056e7e88a21eb79c02c6259'
})

export const getEvents = () => {
  // const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : './backend'

  // return fetch(`${URL}/getEvents.php`)
  //         .then( res => res.json() )
  //         .then( ({ events: {data}}) => data.reverse() )

  return client.getEntries({
    content_type: 'event',
    order: 'fields.startTime'
  })
    .then( (res) => res.items)
    .catch(console.error)
}

export const getYoutubeVideo = () => {
  return client.getEntries({
    content_type: 'youtube',
  })
    .then( (res) => res.items)
    .catch(console.error)
}

export const getContentfulData = (contentTypes) => {
  return client.getEntries({
    content_type: contentTypes ? contentTypes.join(',') : 'event,youtube',
    select: 'sys.id',
    order: 'fields.startTime'
  })
    .then( (res) => res.items)
    .catch(console.error)
}

export const getInstagramFeed = (conf) => {
  const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/getInstagramFeed.php' : './backend/getInstagramFeed.php'
  // const URL = 'https://www.instagram.com/skyline_prague/channel/?__a=1';

  return fetch(URL)
    .then( (res) => {
      console.log('-t-', res);
      return null
    })
}